<template>
  <v-container fluid>
    <transition-group appear name="fadeGroup">
      <v-img key="a" max-height="500" :src="imageSrc">
        <v-row style="height: 100%" align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card-text
              style="color: white"
              class="text-center display-2 font-weight-bold"
              :class="{ 'headline font-weight-bold': $vuetify.breakpoint.xs }"
              >Индивидуальный урок нейрографики
            </v-card-text>
            <v-divider class="white" light />
            <v-card-text
              style="color: white; white-space: pre-line"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              class="headline text-center"
              >Онлайн рисование нейрографики с Надеждой Навроцкой</v-card-text
            >
          </v-col>
        </v-row>
      </v-img>
      <v-row key="b" justify="space-around" align="center" class="flex-column">
        <v-col cols="12" md="10">
          <v-card color="#ECEFF1">
            <v-card-text style="white-space: pre-line" class="text-justify"
              >Индивидуальный урок нейрографики – это возможность рисовать нейрографику по вашему
              запросу под чутким руководством Надежды Навроцкой. Урок длится один час (60 минут),
              проводится по видео связи. В процессе урока вы имеете возможность получить ответы на
              все волнующие вас вопросы – не только по технике рисования нейрографики, но и по
              вашему запросу в целом. <br /><br />
              Индивидуальный урок нейрографики в зависимости от вашего запроса может быть как
              технической помощью и обучением в рисовании нейрографики, так и психологической или
              арт-терапевтической консультацией в том числе с использованием нейрографики. Вы сами
              выбираете формат. <br /><br />
              Для того, чтобы забронировать урок, вам необходимо внести оплату, после чего по
              электронной почте с вами свяжется администратор для выбора удобного дня и времени
              консультации.
            </v-card-text>
            <v-card-actions>
              <v-btn
                :x-small="$vuetify.breakpoint.xs"
                text
                color="#90CAF9"
                :to="`/neurographicsList`"
              >
                <v-icon :x-small="$vuetify.breakpoint.xs" right>mdi-arrow-left</v-icon
                >Вернуться</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="10">
          <v-card :loading="loading">
            <v-card-text
              style="color: #FB8C00"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              class="subtitle-1 text-center"
            >
              Стоимость урока - {{ price }}€
              <br />
              Некоторые продукты вы можете приобрести при помощи внутренней валюты (F).
              <dialog-cmp />
              Покупая продукт, вы соглашаетесь с <dialog-offer-agreement />
            </v-card-text>
          </v-card>
        </v-col>
        <dialog-data-user
          class="my-8 "
          :data-price="dataPrice"
          :data-studio="dataConsultation"
          type-exercise="consultation"
        />
        <v-card-text
          v-if="!user.id"
          style="color: darkred; white-space: pre-line"
          class="text-center"
        >
          <router-link to="/login" tag="span" class="cursorPointer"
            >Чтобы приобрести консультацию, вам необходимо зарегистрироваться. При регистрации вы
            получаете в подарок 10F(муравьедов)
          </router-link></v-card-text
        >
      </v-row>
    </transition-group>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ConsultationBuy',
  metaInfo: {
    title: 'Нейрографика | Индивидуальный урок',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Онлайн рисование нейрографики с Надеждой Навроцкой'
      }
    ]
  },
  components: {
    DialogDataUser: () => import('@/components/shared/dialog/DialogDataUser'),
    DialogCmp: () => import('@/components/shared/dialog/DialogCmp'),
    DialogOfferAgreement: () => import('@/components/shared/dialog/DialogOfferAgreement')
  },
  data: () => ({
    price: 100,
    imageSrc:
      'https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Fnejro-ind-900.jpg?alt=media&token=db0853ed-616f-49fd-bb76-f72f30e4993a'
  }),
  computed: {
    ...mapState({
      loading: state => state.shared.loading,
      user: state => state.user.user
    }),
    dataPrice() {
      if (!this.user.id) {
        return {
          price: '',
          anteaters: ''
        }
      } else {
        return {
          price: this.price,
          anteaters: '',
          titleStudiosPrice: 'neurographic',
          typeExercise: 'individualLesson',
          status: 'Индивидуальный урок',
          typeGroup: 'singly',
          subscription: false,
          subscriptionIntervalCount: 1
        }
      }
    },
    dataConsultation() {
      return {
        id: 'neurographic',
        studioName: 'Нейрографика',
        imageSrc: this.imageSrc,
        author: 'naurotskaya'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
